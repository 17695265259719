<template>
  <div class="app-sidebar" :class="{ collapsed: collapsed }">
    <app-button
      class="app-sidebar-toggle"
      type="link"
      @click="collapsed = !collapsed"
    >
      <icon-arrow-right />
    </app-button>

    <div class="app-sidebar-inner">
      <router-link class="app-sidebar-button app-sidebar-button-home" to="/">
        <div class="app-sidebar-button-icon-box">
          <img
            class="app-sidebar-button-icon"
            src="@/assets/logo-icon.svg"
            alt="Logo"
          />
        </div>
        <div class="app-sidebar-button-text">
          {{ $t('sidebar.links.home') }}
        </div>
      </router-link>

      <div>
        <router-link class="app-sidebar-button" to="/companies">
          <div class="app-sidebar-button-icon-box">
            <icon-suitecase class="app-sidebar-button-icon" />
          </div>
          <div class="app-sidebar-button-text">
            {{ $t('navbar.links.companies') }}
          </div>
        </router-link>
        <router-link class="app-sidebar-button" to="/users">
          <div class="app-sidebar-button-icon-box">
            <icon-user-circle class="app-sidebar-button-icon" />
          </div>
          <div class="app-sidebar-button-text">
            {{ $t('navbar.links.users') }}
          </div>
        </router-link>
      </div>

      <router-link class="app-sidebar-button" to="/jobs/create">
        <div class="app-sidebar-button-icon-box">
          <icon-plus-circle class="app-sidebar-button-icon" />
        </div>
        <div class="app-sidebar-button-text">{{ $t('create_job') }}</div>
      </router-link>

      <div>
        <div class="app-sidebar-title">{{ $t('navbar.links.jobs') }}</div>
        <app-sidebar-jobs class="app-sidebar-jobs" :collapsed="collapsed" />
      </div>

      <div>
        <div class="app-sidebar-title">{{ $t('settings_and_help') }}</div>
        <router-link class="app-sidebar-button" to="/profile">
          <div class="app-sidebar-button-icon-box">
            <icon-settings2 class="app-sidebar-button-icon" />
          </div>
          <div class="app-sidebar-button-text">{{ $t('settings') }}</div>
        </router-link>
        <a
          class="app-sidebar-button"
          :href="
            `https://${$i18n.locale === 'ru' ? 'ru.' : ''}hrblade.com/docs`
          "
        >
          <div class="app-sidebar-button-icon-box">
            <icon-help2 class="app-sidebar-button-icon" />
          </div>
          <div class="app-sidebar-button-text">{{ $t('help') }}</div>
        </a>
      </div>

      <switch-lang class="app-sidebar-lang" />
    </div>
  </div>
</template>

<script>
import AppButton from './AppButton.vue';
import AppSidebarJobs from './AppSidebarJobs.vue';
import IconArrowRight from './icons/ArrowRight.vue';
import IconHelp from './icons/Help.vue';
import IconHelp2 from './icons/Help2.vue';
import IconLangs from './icons/Langs.vue';
import IconPlusCircle from './icons/PlusCircle.vue';
import IconSettings2 from './icons/Settings2.vue';
import IconSuitecase from './icons/Suitecase.vue';
import IconUserCircle from './icons/UserCircle.vue';
import Logo from './Logo.vue';
import LogoIcon from './LogoIcon.vue';
import SwitchLang from './SwitchLang.vue';

export default {
  name: 'AppSidebar',

  components: {
    Logo,
    LogoIcon,
    SwitchLang,
    AppSidebarJobs,
    AppButton,
    IconLangs,
    IconHelp,
    IconArrowRight,
    IconSuitecase,
    IconUserCircle,
    IconPlusCircle,
    IconSettings2,
    IconHelp2
  },

  data() {
    return {
      collapsed: false
    };
  }
};
</script>

<style scoped lang="scss">
.app-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #363151;
  width: 280px;
  flex-shrink: 0;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  &-inner {
    position: sticky;
    height: 100vh;
    overflow: auto;
    padding: 23px 17px 23px 30px;
    top: 0;
    left: 0;

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all 0.15s ease-out;

    &:hover {
      opacity: 0.85;
    }

    &-icon-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      width: 20px;
      height: 100%;
      min-height: 15px;
      margin-right: 8px;
    }

    &-icon {
      display: block;
      width: 15px;
      height: 15px;
    }

    &-home &-icon {
      width: 16px;
      height: 18px;
    }
  }

  &-title {
    font-size: 12px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 7px;
  }

  &-lang {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    padding: 6px 10px 6px 14px;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.3);

    ::v-deep .anticon {
      display: inline-block;
      margin-left: 4px;
    }
  }

  &-toggle {
    position: fixed !important;
    padding: 0 !important;
    width: 26px;
    height: 26px !important;
    top: 16px;
    left: 267px;
    background-color: #ffffff !important;
    border-radius: 50% !important;
    box-shadow: 0 0 12px 0 rgba(150, 152, 163, 0.4) !important;
    z-index: 99;
    transform: rotate(180deg);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px !important;
      height: 12px !important;
    }
  }

  &.collapsed {
    width: 80px;

    .app-sidebar-toggle {
      left: 65px;
      transform: rotate(0deg);
    }

    .app-sidebar-title,
    .app-sidebar-jobs,
    .app-sidebar-lang,
    .app-sidebar-button-text {
      display: none;
    }
  }
}
</style>
